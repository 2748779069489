/* eslint-disable react/no-direct-mutation-state */

import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import makeRequest from "../requestHandler/axiosRequester";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
function Achievers() {
  const CircleCollision = useCallback((main) => {
    loadFull(main);
  }, []);
  

  const slides = [
    {
      image: "../images/Achievers/Mr.Gopala.jpg",
      title: "Mr. Gopalakrishna Kini",
      description: "Application Specialist",
      desc2: "Gulf Corporation of Technology, UAE",
    },
    {
      image: "../images/Achievers/Ramya.jpg",
      title: "Ms. Ramya G S",
      description: "Lab In charge and Quality Manager",
      desc2: "Al Ahalia Hospital, UAE",
    },
    {
      image: "../images/Achievers/viduranga.jpg",
      title: "Mr. Vidhurang",
      description: "",
      desc2: "Northwell Health, North Shore Long Island, Jewish Hospital (LIJ)Laboratories, New York ,USA",
    },
    {
      image: "../images/Achievers/anurada.jpg",
      title: "Mr. W Anuradha L Fernando",
      description: "Director",
      desc2: "Suhada Medical Laboratory Jayamadi Pvt.Ltd ,Srilanka",
    },
  ];
  const [achieversData,setAchieversData]  = useState([])
  const [achievers, setAchievers] = useState(false);
  useEffect(() => {
    getAchieversData()
    setTimeout(() => {
      setAchievers(!achievers);
    }, 3000);
  }, []);
  const getAchieversData = async () => {

    try {
      const url = "https://acahs-website.vercel.app/api/achievers-data";
      // const id = "ACAHS";
      const payload = {
        id: "ACAHS",
        filter:{}
      };
      const response = await makeRequest(url, payload);
      console.log("Achievers", response);
      if (response != null) {
        sessionStorage.setItem('Achievers',JSON.stringify(response))
        setAchieversData(response);
      }
      throw new Error("Fetched data is null");
    } catch (e) {
      console.log("Can't fetch the data : " + e);

    }
  };
  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [sliderRef, setSliderRef] = useState(null);
  const [bannerRef, setBannerRef] = useState(null);
  const achieversBanner = [
    {
      image: "/images/Achievers/achievers2.jpg",
    },
    {
      image: "/images/Achievers/achievers3.jpeg",
    },
    {
      image: "/images/Achievers/achievers1.jpg",
    },
  ];

  return (
    <>
    <div className="overflow-hidden">

  
      <div className="faciliImg ">
        <h1>Alumini</h1>
      </div>

      {achievers && (
        <div className="row px-[4rem] ">
          <div className=" container container-fluid my-5  ">
            <Slider ref={setBannerRef} {...settings}>
              {achieversBanner.map((card, index) => (
                <div key={index}>
                  <div className="facCard rounded ">
                    <div className="flex justify-center p-0">
                      <img src={card.image} alt="" className="bannerImg shadow-md" />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
      
      <div className="achievers">
      <div className="text-center my-0 founder-head">Rank Holders</div>
      <div className="carousel container  carouselfac p-[3rem]">
          {
            <Slider ref={setSliderRef} {...sliderSettings}>
              {achieversData.map((card, index) => (
                <div key={index}>
                  <div className="facCard shadow-md m-2 rounded bg-white ">
                    <div className="card-body p-0">
                      <img
                        src={card.Image}
                        alt=""
                        className="w-10 card-img-top"
                      />
                      <div className="p-4">
                        <h5 className="mb-0">
                          {card.Name}
                        </h5>
                        <p className="medium bold">{card.rank}</p>
                        <p className="medium text-muted">{card.DeptName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10%%",
            }}
          >
          </div>
        </div>


        <div className="text-center my-0 founder-head">Placements</div>

        <div className=" container container-fluid my-8 p-[3rem]">
          <Slider ref={setSliderRef} {...sliderSettings}>
            {slides.map((card, index) => (
              <div key={index}>
                <div className="facCard shadow-md m-2 rounded facCard bg-white">
                  <div className="card-body p-0">
                    <img src={card.image} alt="" className=" card-img-top" />
                    <div className="p-4">
                      <h5 className="mb-0">{card.title}</h5>
                      <p className="small">{card.description}</p>
                      <p className="small text-muted">{card.desc2}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </div>
    </>
  );
}

export default Achievers;
