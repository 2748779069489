import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {LaboratoryData} from '../../constants'
function Laboratory() {
  

  return (
    <>
      <div className="facility-header text-center my-5 founder-head">
        Laboratory
      </div>
      <div className="facility-slider my-5  mx-8 ">
        <Carousel className="main-slide">
          {LaboratoryData.map((lab) => (
            <div class="Facility-Slider-Img">
              <img src={lab.img} loading="lazy"/>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Laboratory;
