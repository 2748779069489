import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import makeRequest from "../requestHandler/axiosRequester";
// import { Link } from "react-router-dom";


function Mltspcl() {
  const [data, setData] = useState("");
  let Navigate = useNavigate();
  const parentToChild = (title) => {
    setData(title);
    Navigate("/enroll/" + title);
  };

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCourseData();

    console.log(data);
  }, []);

  const getCourseData = async () => {
    setLoading(true)
    try {
    console.log(sessionStorage.getItem('sdfsd'))
      const url = "https://acahs-website.vercel.app/api/course-data";
      // const id = "ACAHS";
      const payload = {
        id: "ACAHS",
        filter:{}
      };

      const response = await makeRequest(url, payload);
      console.log("COURSES", response);
      if (response != null) {
        sessionStorage.setItem('Courses',JSON.stringify(response.data))
        setCourses(response.data);
      }
      throw new Error("Fetched data is null");
    } catch (e) {
      console.log("Can't fetch the data : " + e);

      setLoading(false);
    }
  };
  return (
    <div>
      <div className="container">
        <div className="mainHead text-center my-6">
          <h5>Courses offered by MSc. MLT</h5>
        </div>
        <div className="text-lg my-9 p-8">
          <p className="">
            M.Sc in Medical Laboratory Technology is a two years post graduate
            programme for students looking forward to building their career as
            Medical Laboratory Scientist. The course is designed to impart
            students theoretical and practical knowledge of Microbiology,
            Biochemistry, Toxicology, Immunology, Blood banking, Research, etc
          </p>
        </div>

        <div className="pgProgram" id="pgBack">
          <div className="row">
            {courses &&
              courses.length > 0 &&
              courses.map((courseData) => {
                if (courseData.CourseType === "MLT Speciality")
                  return (
                    <>
                      <div className="col-lg-6">
                        <div className="card mb-3 course-card-bg">
                          <div className="row g-0" key={courseData.CourseName}>
                            <div className="col-md-4">
                              <img
                                src={courseData.CourseImage}
                                className="img-fluid rounded-start card-img"
                                alt="Course Image"
                              />
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h5 className="card-title">
                                  {courseData.CourseName}
                                </h5>
                                <p className="card-text">
                                {courseData.CourseDesc.length> 160 ? courseData.CourseDesc.slice(0,160) +'...(Click know more)' : courseData.CourseDesc}
                                </p>
                                <button
                                  className="btn btn-primary card-btn"
                                  onClick={(e) => {
                                    parentToChild(courseData.CourseName);
                                  }}
                                >
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mltspcl;
