import { Spinner } from "react-bootstrap";
import React from "react";


const Loading = ({ size = 60 }) => {




  return (
    <div
      style=
      {{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "20vh",
      }}
      >
      <Spinner
        style={{
          width: size,
          height: size,
        }}
        animation="border"
        variant="success"
      />
    </div>
  );
};

export default Loading;
