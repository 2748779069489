import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import {Hosteldata} from "../../constants"
function Hostel() {
 
  return (
    <>
      <div className="facility-header text-center my-5 founder-head">
        Hostel
      </div>
      <div className="facility-slider my-5  mx-8 ">
      <Carousel className="main-slide">
        {Hosteldata.map((Hostel) => (
          <div class="Facility-Slider-Img">
            <img src={Hostel.imgscr} loading="lazy" />
          </div>
        ))}
      </Carousel>
      </div>
    </>
  );
}

export default Hostel;
