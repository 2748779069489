import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import {Librarydata} from '../../constants'

function Library() {
  

 return (
   <>


     <div className="facility-header text-center my-5 founder-head">Library</div>{" "}
     <div className="facility-slider my-5  mx-8 ">
     < Carousel className="main-slide">

       {Librarydata.map((Library) => (
       
          
             <div class="Facility-Slider-Img">
               <img src={Library.img} loading="lazy"/>
             </div>
         
       ))}
              </Carousel>

     </div>
   </>
 );
}

export default Library;
