import React, { useEffect, useState, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Spinner } from "react-bootstrap";

import { motion } from "framer-motion";
import makeRequest from "../requestHandler/axiosRequester";
import { env } from "process";
import ErrorMessage from "../admin/ErrorMessage";
import Loading from "../admin/Loading";

// const dotenv = require("dotenv");

function Faculty() {
  // dotenv.config();
  const [faculty, setFaculty] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInput = async () => {
    setLoading(true);
    try {
      const url = "https://acahs-website.vercel.app/api/faculty-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log(response);
      if (!response) {
        throw new Error("Response is null");
      } else {
        setLoading(false);
        setFaculty(response);
      }
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      setLoading(false);
      setFaculty(false);
    }
  };
  useEffect(() => {
    getInput();
  }, []);

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [sliderRef, setSliderRef] = useState(null);
  if (faculty.length === undefined) {
    return (
      <>
        {/* <div className="my-3">{<Loading />}</div> */}
      </>
    );
  } else {
    return (
      <>
        <div className=" mt-9 founder-head">
          <h5>Faculties</h5>
        </div>
        <div className="carousel container  carouselfac p-[3rem]">
        <p className ="my-3 text-slate-500  font-semibold text-xl">Medical Laboratory Technology</p>
          {
            <Slider ref={setSliderRef} {...sliderSettings}>
              {faculty.map((card, index) => {
              if(card.department=='MLT'){
               return( <div key={index}>
                  <div className="facCard shadow-md m-2 rounded bg-white ">
                    <div className="card-body p-0">
                      <img
                        src={card.facimage}
                        alt=""
                        className="w-10 card-img-top"
                      />
                      <div className="p-4">
                        <h5 className="mb-0">
                          {card.fname} {card.mname} {card.lname}
                        </h5>
                        <p className="medium text-muted">{card.designation}</p>
                      <p className="medium text-muted">{card.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
               ) } }

              )}
            </Slider>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10%%",
            }}
          >
          </div>
        </div>

         

        <div className="carousel container  carouselfac p-[3rem]">
        <p className ="my-3 text-slate-500  font-semibold text-xl">OT & AT </p>
          {
            <Slider ref={setSliderRef} {...sliderSettings}>
              {faculty.map((card, index) => {
              if(card.department=='ANS'){
              return(  <div key={index}>
                  <div className="facCard shadow-md m-2 rounded bg-white">
                    <div className="card-body p-0">
                      <img
                        src={card.facimage}
                        alt=""
                        className="w-10 card-img-top"
                      />
                      <div className="p-4">
                        <h5 className="mb-0">
                          {card.fname} {card.mname} {card.lname}
                        </h5>
                        <p className="medium text-muted">{card.designation}</p>
                      <p className="medium text-muted">{card.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
                 )   } }

              )}
            </Slider>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10%%",
            }}
          >
          </div>
        </div>
          

        <div className="carousel container  carouselfac p-[3rem]">
        <p className ="my-3 text-slate-500  font-semibold text-xl">Clinical Psychology</p>
          {
            <Slider ref={setSliderRef} {...sliderSettings}>
              {faculty.map((card, index) => {
              if(card.department=='PSY'){
             return(   <div key={index}>
                  <div className="facCard shadow-md m-2 rounded bg-white ">
                    <div className="card-body p-0">
                      <img
                        src={card.facimage}
                        alt=""
                        className="w-10 card-img-top"
                      />
                      <div className="p-4">
                        <h5 className="mb-0">
                          {card.fname} {card.mname} {card.lname}
                        </h5>
                        <p className="medium text-muted">{card.designation}</p>
                      <p className="medium text-muted">{card.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
               ) } }

              )}
            </Slider>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10%%",
            }}
          >
          </div>
        </div>


        <div className="carousel container  carouselfac p-[3rem]">
        <p className ="my-3 text-slate-500  font-semibold text-xl">Hospital Administration</p>
          {
            <Slider ref={setSliderRef} {...sliderSettings}>
              {faculty.map((card, index) => {
              if(card.department=='BHA'){
             return(   <div key={index}>
                  <div className="facCard shadow-md m-2 rounded bg-white ">
                    <div className="card-body p-0">
                      <img
                        src={card.facimage}
                        alt=""
                        className="w-10 card-img-top"
                      />
                      <div className="p-4">
                        <h5 className="mb-0">
                          {card.fname} {card.mname} {card.lname}
                        </h5>
                        <p className="medium text-muted">{card.designation}</p>
                      <p className="medium text-muted">{card.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
               ) } }

              )}
            </Slider>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "10%%",
            }}
          >
          </div>
        </div>



      </>
    );
  }
}
export default Faculty;
