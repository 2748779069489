import React from "react";
import Hostel from "./Hostel";
import CircleCollisions from '../particles/FillCircles.json'
import Library from "./Library";
import Classroom from "./Classroom";
import Laboratory from "./Laboratory";
import Particles from 'react-tsparticles'
import { useCallback } from "react";
import {loadFull} from 'tsparticles'

function Facilities() {
  const CircleCollision = useCallback(main =>{
    loadFull(main)
  },[])

  return (
    <>
      <div className="faciliImg text-center">
        <h1>Facilities</h1>
      </div>
      <div className="particle-holder">
<Particles  options={CircleCollisions} init={CircleCollision}  />

</div>
      <div className="container-facility">
      <Classroom />
      <Laboratory />
      <Library />
        <Hostel/>

       
    
      </div>
    </>
  );
}

export default Facilities;
