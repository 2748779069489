import React from "react";

function Allied() {


    
  return (
    <>
    <div className=" allied ">

   <div className="row">
      <div className="founder-head ">
        <h1>Allied Health Science</h1>
      </div>
      <div className="allied-desc  ">
        <p>
          Allied Health Science professionals play a crutial role and are the
          support pillars of the health care system. Allied Health profession
          include individuals involved with the delivery of health related
          service with expertise contributing in the therapeutic, diagnostic,
          curative preventive and rehabilitative interventions. Currently India
          faces acute shortage of Allied Health Science professionals, the UG
          and PG programme we offer under Rajiv Gandhi University of Health
          Sciences are of ample placement opportunities and greater carrier
          prospectus both in India and abroad as well, for students. ACAHS is
          committed to provide high quality educational experience that will
          prepare qualified, humble and motivated individuals for a better
          tomorrow.
        </p>
      </div>
      <div className="allied-img col-12 col-lg-7">
        <img src="" alt="" />
      </div>
      </div>
      </div>
    </>
  );
}

export default Allied;
