  // eslint-disable-next-line
  import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import makeRequest from "../requestHandler/axiosRequester";
import Modal from "react-bootstrap/Modal";

// const dotenv = require("dotenv").config();

function AdminEnroll() {
  let navigate = useNavigate();
  const [enrollData, setEnrollData] = useState([]);
  const [show, setShow] = useState(false);
  const [enroleid, setEnroleid] = useState("Null");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchEnroll = async () => {
    try {
      const url = "https://acahs-website.vercel.app/api/enroll-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      setEnrollData( response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      
    }
    
  };
  // ();

  useEffect(() => {  
    fetchEnroll();
  }, []);
  

  const deleteEnroll = async(id) => {
    
    const url = `https://acahs-website.vercel.app/api/enrollList/delete/${id}`;
    try {
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      console.log(response);
    } catch (e) {
      console.log("Can't fetch the data : ", e); 
    }

  };

  return (
    <>
      <div className="container founder-head faculty ">
          <h5 className="">Enroll Data</h5>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Delete enroll?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please ensure you don't need the response. If so press delete button </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              console.log("enrole id :", enroleid);
              deleteEnroll(enroleid);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <table id="admintable">
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>State</th>
            <th>Phone</th>
            <th>Subject</th>
            <th>Operation</th>
          </tr>
          {enrollData.map((enroll) => (
            <tr>
              <td>{enroll.fname}</td>
              <td>{enroll.mname}</td>
              <td>{enroll.lname}</td>
              <td>{enroll.email}</td>
              <td>{enroll.country}</td>
              <td>{enroll.state}</td>
              <td>{enroll.phone}</td>
              <td>{enroll.subject}</td>

              <td>
                <button
                  onClick={() => {
                    setEnroleid(enroll._id)
                    handleShow();
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default AdminEnroll;
