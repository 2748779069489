import React, { useEffect, useState } from "react";
import { Form, Button, Row } from "react-bootstrap";
import axios from "axios";
import ErrorMessage from "./../ErrorMessage";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

function InsFaculty() {
  let navigate = useNavigate();

  const userInfo = sessionStorage.getItem("userCred");
  useEffect(() => {
    if (!userInfo) {
      navigate("/admin");
    }
  }, []);

  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [facimage, setfacImage] = useState(
    "https://res.cloudinary.com/acahscollege/image/upload/v1655266445/Screenshot_2_jnsz6x.png"
  );
  const [imgMessage, setImageMessage] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      setLoading(true);
      const { data } = await axios.post(
        "https://acahs-website.vercel.app/api/users/insData/faculty",
        {
          fname,
          mname,
          lname,
          email,
          phone,
          designation,
          department,
          location,
          facimage,
        },
        config
      );
      console.log(data);
      setLoading(false);
      navigate("/admin");
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError(error.response.data.message);
    }
  };

  const postDetails = (pics) => {
    setLoading(true);
    if (!pics) {
      setLoading(false);
      return setImageMessage("Please select an Image file!!");
    }
    setImageMessage(null);
    if (
      pics.type === "image/jpeg" ||
      pics.type === "image/png" ||
      pics.type === "image/jpg"
    ) {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "acahsimages");
      data.append("cloud_name", "acahscollege");
      fetch("https://api.cloudinary.com/v1_1/acahscollege/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setfacImage(data.url.toString());
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      return setImageMessage("Please select an image!");
    }
  };

  return (
    <>
      <div className="container">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
        {loading && <Loading />}

        <Form onSubmit={submitHandler}>
          <Row>
            <Form.Group className="col-4" controlId="Name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={fname}
                placeholder="Faculty Fisrt Name"
                onChange={(e) => setFname(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-4" controlId="Name">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                value={mname}
                placeholder="Faculty Middle Name"
                onChange={(e) => setMname(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-4" controlId="Name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lname}
                placeholder="Faculty Last Name"
                onChange={(e) => setLname(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-4" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-4" controlId="formBasicName">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="number"
                value={phone}
                placeholder="Enter your numer"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-4" controlId="content">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                value={designation}
                placeholder="Designation"
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-4" controlId="content">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                value={department}
                placeholder="Department"
                onChange={(e) => setDepartment(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-4" controlId="content">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                value={location}
                placeholder="location"
                onChange={(e) => setLocation(e.target.value)}
              />
            </Form.Group>
            {imgMessage && (
              <ErrorMessage varaint="danger">{imgMessage}</ErrorMessage>
            )}
            <Form.Group className="col-4">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                id="custom-file"
                custom="true"
                placeholder="Upload image"
                onChange={(e) => postDetails(e.target.files[0])}
              />
            </Form.Group>
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default InsFaculty;
