import React, { useState, useEffect } from "react";
import Mltspcl from "./Mltspcl";
import Pgcourses from "./Pgcourses";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import CircleCollisions from "../particles/FillCircles.json";
import makeRequest from "../requestHandler/axiosRequester";
import { Link, Navigate, useNavigate } from "react-router-dom";

function Courses() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sample, setSample] = useState("this is from courses");
  console.log("this is sample", sample);
  // const [data, setData] = useState("");
  const [notifications, setNotification] = useState([]);

  useEffect(() => {
    const coursesStorage = sessionStorage.getItem('Courses')
    getCourseData();
    // if(!coursesStorage){
    //   console.log(coursesStorage);
    // }
    fetchNotif();
  }, []);

  const getCourseData = async () => {
    setLoading(true)
    try {
   
      const url = "https://acahs-website.vercel.app/api/course-data";
      // const id = "ACAHS";
      const payload = {
        id: "ACAHS",
        filter:{}
      };

      const response = await makeRequest(url, payload);
      console.log("COURSES", response);
      if (response != null) {
        sessionStorage.setItem('Courses',JSON.stringify(response.data))
        setCourses(response.data);
      }
      throw new Error("Fetched data is null");
    } catch (e) {
      console.log("Can't fetch the data : " + e);

      setLoading(false);
    }
  };

  let Navigate = useNavigate();
  const parentToChild = (title) => {
    // setData(title);
    Navigate("/enroll/" + title);
  };

  const CircleCollision = useCallback((main) => {
    loadFull(main);
  }, []);

  const fetchNotif = async () => {
    // setLoading(true);
    const id = "ACAHS";
    const url = "https://acahs-website.vercel.app/api/notification-data";
    // const filter ={}

    const payload = {
      id,
    };
    const response = await makeRequest(url, payload);

    if (!response) {
      throw new Error("Can't fetch the notification");
    }
    // setLoading(false);
    setNotification(response.data);
  };
  // console.log(notifications.Description);
  return (
    <>
      {/* {console.log("Fetched UG Courses :", courses)}
      {console.log("Notification data this :", notifications)} */}
      <div className="faciliImg">
        <h1>Courses</h1>
      </div>

      <div className="particle-holder">
        <Particles options={CircleCollisions} init={CircleCollision} />
      </div>
      <div className="course-card mx-3  md:mx-28  ">
        <div className="mainHead text-center ">
          <h1>UG Programmes</h1>
          <h5> Bachelor of Science in Allied Health Science </h5>
        </div>
      </div>
      <div className="container overflow-hidden">
        {notifications && notifications.length > 0 && (
          <marquee
            width="90%"
            direction="right"
            height="100px"
            className="marquee"
          >
            <p>{`${notifications[0].Description}`}</p>
          </marquee>
        )}
      </div>
      <div className="ugProgram mx-3 md:mx-28" id="pgBack">
        <div className="row">
          {courses &&
            courses.length > 0 &&
            courses.map((courseData) => {
              if (courseData.CourseType === "UG")
                return (
                  <>
                    <div className="col-lg-6 ">
                      <div className="card mb-3 course-card-bg ">
                        <div className="row g-0" key={courseData._id}>
                          <div className="col-md-4 ">
                            <img
                              src={courseData.CourseImage}
                              className="img-fluid rounded-start card-img"
                              alt="Course Image"
                            />
                          </div>
                          <div className="col-md-8 ">
                            <div className="card-body">
                              <h5 className="card-title">
                                {courseData.CourseName}
                              </h5>
                              <p className="card-text">
                                {courseData.CourseDesc.length> 160 ? courseData.CourseDesc.slice(0,160) +'...(Click know more)' : courseData.CourseDesc}
                              </p>

                              <div>
                                <button
                                  className="btn btn-primary card-btn"
                                  onClick={(e) => {
                                    parentToChild(courseData.CourseName);
                                  }}
                                >
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
            })}
        </div>
      </div>

      <Pgcourses />
      <Mltspcl />
    </>
  );
}

export default Courses;
