import React from 'react'
import Particles from 'react-tsparticles'
import { useCallback } from "react";
import {loadFull} from 'tsparticles'
import CircleConnect from '../particles/CircleConnect.json'

export default function CircleConnected() {
    const HexaParticle = useCallback(main =>{
        loadFull(main)
      },[])
  return (
    
   <div className="particle-holder">
    <Particles options={CircleConnect} init={HexaParticle}  />
    </div> 
  )
}
