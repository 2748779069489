import React from "react";
import video from './FeatureVideo.mp4'
import Fade from 'react-reveal/Fade';




function Features() {
  return (
    <>
      <div className="allied">
        <div className="feature-head founder-head  ">
          <h1>Unique Features</h1>
        </div>
       

        <div className="row ">
       <Fade  cascade duration={1800}>
          <div className="col-lg-6 col-12 allied-desc feat-desc">
            <ul>
              <li>
                Vibrant Campus. <br />
              </li>
              <li>
                Activity Based Learning. <br />
              </li>
              <li>
                Highly qualified and competent teaching staff with rich
                healthcare and academic experience. <br />
              </li>
              <li>
                State of art infrastructure. <br />
              </li>
              <li>
                Continuous curriculum on Personality development, communication
                skill and soft skills. <br />
              </li>
              <li>
                Practical training in state of laboratories and hospitals.
                <br />
              </li>
              <li>
                100% placement assistance. <br />
              </li>
              <li>
                Hostel and transportation Facility.
             
                Separate hostel facilities for boys and girls with a multi-
                cusine mess. <br />
              </li>
            </ul>


  
          </div>
          </Fade>

          <Fade right>
          <div className="col-lg-6 col-12">
          <video src={video} controls loop={true} mute></video>
          </div>
          </Fade>
        </div> 
      </div>
    </>
  );
}

export default Features;
