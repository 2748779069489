import React, { useRef } from "react";
import gsap from "gsap";
import { useIntersection } from "react-use";
import Fade from "react-reveal/Fade";
function Principal() {
  return (
    <>
      {/* <div className="px-3 md:px-32 grid place-content-center">

      <div className=" founder ">
        <div className="row px-3 md:px-32">
          <Fade cascade duration={1800}>

          <div className="founders-words  col-lg-5 col-12 " >
            <p >
            <h1 className="founder-head my-5 ">Principal's Message</h1>
              
              “A desire can change no things, a decision can change something
              but a determination can change everything”. It is my pleasure to
              welcome you to Alva’s College of Allied Health Sciences
              website. An Institution under prestigious Alva’s Education
              Foundation Moodubidre, Karnataka, has set a Motto of “Moulding
              better tomorrow”. Alva’s College of Medical Laboratory Technology
              is an Institution that strives for all over development of our
              students.  We are
              constantly reviving our setup up to date and improve while making
              sure that student gains thinking skills, analytical framework,
              entrepreneurial skills inter personal and communication skills.
               Hope you will Find all the required
              information that you are seeking for from our college website. You
              are always welcome to pay a visit to our college or contact us for
              any other information that you require.
            </p>
          </div>
          </Fade>
          <Fade right duration={1800}>
          <div className="founder-head col-12 col-lg-5 text-center allied " >

            <img src="https://res.cloudinary.com/acahscollege/image/upload/v1656048881/faculty/uhnvoxqle6ein8gmjx45.jpg" className="object-contain" title="Dr. Shankar Shetty" />
            <h3>Prof.Dr. Shankar Shetty, PhD. DHN</h3>
            <h2>Principal, ACAHS</h2>
          </div>
          </Fade>
        </div>
      </div>
    </div> */}
      <div className="grid md:grid-cols-2 min-h-[90vh] md:content-center justify-items-center">
        <div className=" ">
          <div className=" mt-[10rem] founder-head   md:mt-0">
            <h1 className="text-center md:text-left">Principal's Message</h1>
          </div>
          <p className="founders-words">
            “A desire can change no things, a decision can change something but
            a determination can change everything”. It is my pleasure to welcome
            you to Alva’s College of Allied Health Sciences website. An
            Institution under prestigious Alva’s Education Foundation
            Moodubidre, Karnataka, has set a Motto of “Moulding better
            tomorrow”. Alva’s College of Medical Laboratory Technology is an
            Institution that strives for all over development of our students.
            We are constantly reviving our setup up to date and improve while
            making sure that student gains thinking skills, analytical
            framework, entrepreneurial skills inter personal and communication
            skills. Hope you will find all the required information that you are
            seeking for from our college website. You are always welcome to pay
            a visit to our college or contact us for any other information that
            you require.
          </p>
        </div>
        
            <Fade right duration={1800}>
              <div className="founder-head   flex flex-col items-center  ">
                <img
                  src="https://res.cloudinary.com/acahscollege/image/upload/v1656048881/faculty/uhnvoxqle6ein8gmjx45.jpg"
                  className="princiImg"
                  title="Dr. Shankar Shetty"
                />
                <h3>Prof.Dr. Shankar Shetty, PhD. DHN</h3>
                <h2>Principal, ACAHS</h2>
              </div>
            </Fade>
          
       
      </div>
    </>
  );
}

export default Principal;
