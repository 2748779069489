import React, { useState, useEffect } from "react";
import axios from "axios";
import Contact from "./../contact/Contact";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import makeRequest from "../requestHandler/axiosRequester";


// const dotenv = require("dotenv").config();

function AdminCourses() {
  let navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const [show, setShow] = useState(false);
  const [courseID, setCourseID] = useState("Null");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchCourse = async () => {
    try {
  //  const Courses =  sessionStorage.getItem('Courses');
    //  console.log(Courses);
      // const Courses = JSON.parse(Courses);
      const url = "https://acahs-website.vercel.app/api/course-data";
      // const id = "ACAHS";
      const payload = {
        id: "ACAHS",
        filter:{}
      };
      const response = await makeRequest(url, payload);
      console.log("Fetched courses :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      setCourseData(response.data);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
    }
  };
  useEffect(() => {
    fetchCourse();
  },[]);

  const deleteCourse = async (courseid) => {
    console.log("Deleting course.........", courseid);
    const url = `https://acahs-website.vercel.app/api/courseList/delete/${courseid}`;
    const id = "ACAHS";
    const payload = {
      id,
    };

    try {
      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      console.log(response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
    }
  };

  return (
    <>
       
      <div className="container founder-head faculty">
          <h5>Courses</h5>
        </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete course?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please ensure you don't need the response. If so press delete button </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              console.log("courseID :", courseID);
              deleteCourse(courseID);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <table id="admintable">
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Duration</th>
            <th>Description</th>
            {/* <th>Eligibility</th> */}
            <th>Image</th>
            <th>Action</th>
          </tr>
          {console.log(courseData)}
          {courseData!=[] && courseData.map((course) => (
            <tr key={courseData._id}>
              <td>{course.CourseName}</td>
              <td>{course.CourseType}</td>
              <td>{course.courseDuration}</td>
              <td>{course.CourseDesc}</td>
              {/* <td>{course.CourseEligibility}</td> */}
 
              <td>
                      <img className= "Admineveimg" src={course.CourseImage} alt="" />
                    </td>

              <td>
                <button
                  onClick={() => {
                    {
                      setCourseID(course._id);
                      handleShow();
                    }
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default AdminCourses;
