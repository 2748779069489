import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import SnowDrops from "../particles/SnowDrop.json";
function Footer() {
  const [date, setDate] = useState();

  const getYear = () => {
    setDate(new Date().getFullYear());
  };

  useEffect(() => {
    getYear();
  }, []);
  const SnowDrop = useCallback((main) => {
    loadFull(main);
  }, []);
  return (
    <>
      <div className="footer-dark">
        <Link to="/login">Login</Link>
        <footer>
          <div className="container">
            <a href="https://alvas.org" target="_blank">
              <img
                src="./images/logo1.png"
                className="image-fluid contactLogo"
                alt=""
              />
            </a>
            <div className="row">
              <div className="col-sm-6 col-md-3 item">
                <h3>Featured Links</h3>
                <ul>
                  <li>
                    <Link to="/accreditation">Accreditation</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item">
                <h3>About</h3>
                <ul>
                  <li>
                    <Link to="/history">History</Link>
                  </li>
                  {/* <li>
                    <Link to="/whyacahs">Why ACAHS?</Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-6 item text">
                <h3>Information</h3>
                <p>
                  Alva's College of Allied Health Science,<br></br>
                  Alva's Health Center Complex Moodubidri, 574277
                </p>
              </div>

              <div className="text-center p-6 footer-dark contact my-2">
                <a href="/" target="_blank" className="mx-2  ">
                  <i className="far fa-envelope mx-1"></i>
                  <a href="mailto:acahs@alvas.org">acahs@alvas.org</a>
                </a>

                <a
                  to="/"
                  target="_blank"
                  className="mx-2 "
                  mailto="acahs@alvas.org"
                >
                  <i className="fa-solid fa-phone mx-1"></i>
                  <a href="tel:+918147760397">+918147760397</a>,&nbsp;
                  <a href="tel:+918317357019">+918317357019</a>,&nbsp;
                  <a href="tel:08258 238104-111">08258 238104-111</a>
                </a>
              </div>

              <div className="col item social">
                <a href="https://www.facebook.com/alvaseducationfoundation/">
                  <i className="icon ion-social-facebook"></i>
                </a>
                <a href="https://www.youtube.com/c/AlvasMadhyama">
                  <i className="icon ion-social-youtube"></i>
                </a>
                <a href="#">
                  <i className="icon ion-social-instagram"></i>
                </a>
              </div>
            </div>
            <p className="copyright">
              Copyright &copy; {date} <span>ACAHS</span> All Rights Reserved{" "}
              <br />
              Developed by{" "}
              <a
                target="_blank"
                href="https://deltanlobo.vercel.app"
                style={{ color: "#fff" }}
              >
                Deltan
              </a>{" "}
              &{" "}
              <a
                href="https://github.com/ThejasDevadiga"
                target="_blank"
                style={{ color: "#fff" }}
              >
                Thejas
              </a>{" "}
              @ AIET
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
