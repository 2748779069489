import React, { useEffect } from "react";

function History() {
  return (
    <>
      <div className="container">
        <div className="founder-head hist-head">
          <h1>History</h1>
        </div>
        <div className="row">
          <div className="history side-head fs-5">
            About Medical Laboratory Technology
          </div>
          <div className="col-lg-6 col-12">
            <div className="aboutclg ">
              As long as Physicians add years to the life, Laboratory
              technologists add quality to it. Its origin dates back to the
              B.C’s with eminent contributions from Aristotle, Socrates, Charka,
              to the modern Louis Pasteur, Land Steiner etc to mention a few.
              Today thousands of laboratory technologists, offer services 24
              hours a day 365 days a year all over the world. Not merely are
              their services required in diagnostic laboratories alone but also
              in production, research and academic areas with an aim, that man
              may live his life span in perfect harmony. Medical fraternity is
              experiencing technological advancement at a breath taking pace,
              revolutionizing with it the health care industry. This gave raise
              to attractive career option in the form of medical laboratory
              technologist who are entrusted with the responsibility to maintain
              diagnostic facilities and blood banks. Perform and analyze
              clinical chemistry, hematology, immunology and microbiological
              tests . You need not be an Einstein to predict that the need for
              laboratory technology will grow phenomenally through out the world
              as latest advance in investigative procedures keep pouring in.
              Alva’s College of MLT promises to take you through the edge of
              this scientific revolution with its aesthetic infrastructure and
              rigorous curriculum in process of shaping you up for a career in
              this exciting stream
            </div>
          </div>
          <div className=" historyImg col-lg-6 col-12">
            <img src="./images/college.jpg" className="collegeImg" />
          </div>

          <div className="history side-head fs-5">Why Allied Health Sciences ?</div>
          <div className="col-lg-12">
            <div className="why-acahs aboutclg">
              Due to the foresight of Dr. M. Mohan Alva, the college came into
              existence in the year 1999- 2000. Today, within a short span it
              has elevated itself into an embodiment of quality education in the
              field of Medical Laboratory Technology in the line with the best
              Institution in the country it is also an abode of rich culture and
              traditional extravaganza of the country as it has vibrant mix of
              students from across the country and world, who cherish each
              moment of their stay here.




            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default History;
