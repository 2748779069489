// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import makeRequest from "../requestHandler/axiosRequester";
import { Link } from "react-router-dom";
// import {motion} from "framer-motion";
function Pgcourses() {
  const [data, setData] = useState("");

  let Navigate = useNavigate();

  const parentToChild = (title) => {
    setData(title);
    Navigate("/enroll/" + title);
  };

  const [page, setPage] = useState("");
 
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    getCourseData();
  }, []);

  const getCourseData = async () => {
    try {
      const url = "https://acahs-website.vercel.app/api/course-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched PG courses :", response);
      if (response != null) {
        setCourses(response.data);
      }
      throw new Error("Fetched data is null");
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      // setLoading(false);
    }
  };

  return (
    <div>
      <div className="course-card2 mx-3 md:mx-28 ">
        <div className="mainHead  text-center">
          <h1>PG Programmes</h1>
          <h5>Masters of Science in Allied Health Science</h5>
        </div>
      </div>
      <div className="PGCourses mx-3 md:mx-28">
        <div className="pgProgram" id="pgBack">
          <div className="row">
            {courses &&
              courses.length > 0 &&
              courses.map((courseData) => {
                if (courseData.CourseType === "PG" && courseData.CourseName !== "Masters in Medical Laboratory Technology")
                  return (
                    <>
                      <div className="col-lg-6">
                        <div className="card mb-3 course-card-bg">
                          <div className="row g-0" key={courseData._id}>
                            <div className="col-md-4">
                              <img
                                src={courseData.CourseImage}
                                className="img-fluid rounded-start card-img"
                                alt="Course Image"
                              />
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h5 className="card-title">
                                  {courseData.CourseName}
                                </h5>
                                <p className="card-text">
                                {courseData.CourseDesc.length> 160 ? courseData.CourseDesc.slice(0,160) +'...(Click know more)' : courseData.CourseDesc}
                                </p>

                                <div className="flex">
                                  <button
                                    className="btn btn-primary card-btn"
                                    onClick={(e) => {
                                      parentToChild(courseData.CourseName);
                                    }}
                                  >
                                    Know More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pgcourses;
