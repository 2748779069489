/* eslint-disable react/no-direct-mutation-state */

import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import ErrorMessage from "./ErrorMessage";
import { useNavigate } from "react-router-dom";

const Login = ({}) => {
  let navigate = useNavigate();
  const userInfo = sessionStorage.getItem("userCred");
  useEffect(() => {
    if (userInfo) {
      navigate("/admin");
    }
  }, []);

  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Request-Headers": "*",
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IkFDQUhTIiwiaWF0IjoxNjc4Njg5MTUzLCJleHAiOjE2ODEyODExNTN9.uAF1QCf_Hy0dXRaoyFioXhZH_gtXiYHHIlx_URBUUp8",
        },
      };
      setLoading(true);
      const { data } = await axios.post(
        "https://acahs-website.vercel.app/api/users/login",
        {
          adminEmail,
          password,
        },
        config
      );
      console.log(data);
      if (data) {
        sessionStorage.setItem("userCred", JSON.stringify(data));
        setLoading(false);
        navigate("/admin");
      } else {
        throw new Error("Error while inserting data");
      }
    } catch (error) {
      console.log(error);
      // setError(error);
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="">
        <img
          className="d-block mx-auto mb-4"
          src="./images/logo1.png"
          alt=""
          width="72"
          height="57"
        />
      </div>

      <div className="LoginContainer">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={adminEmail}
              placeholder="Enter email"
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" className="btn btn-primary card-btn" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
