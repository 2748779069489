import { useState } from "react";
import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import ErrorMessage from "../admin/ErrorMessage";
import Loading from "../admin/Loading";
import makeRequest from "../requestHandler/axiosRequester";


function Contact() {
  const [fname, setFname] = useState("");
  const [sname, setSname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [subject, setSubject] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const id = "ACAHS";
      const url = "https://acahs-website.vercel.app/api/users/contact";
      const payload = {
        id,
        fname,
        sname,
        lname,
        email,
        country,
        state,
        phone,
        subject,
        comment,
      };

      const response = await makeRequest(url, payload);
      // console.log("Fetched data :", response);
      if (!response) {
        throw new Error("Can't fetch the data");
      }
      setLoading(false);
      setMessage(
        `Hello ${response.fname} your response has been sent. Thank you for contacting.`
      );
    } catch (error) {
      console.log(error);
      // setError(error.message);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className=" md:px-32">
        <div className="md:flex p-3 justify-center items-center h-screen">
          <iframe
          className="md:mt-16 mt-6 md:rounded-2xl rounded-xl"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124367.24377907565!2d74.84089359726559!3d13.068967800000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4aaec6042aec1%3A0x1602e55a40719f10!2sAlvas%20College%20of%20Nursing!5e0!3m2!1sen!2sin!4v1686113574822!5m2!1sen!2sin"
            width="100%"
            height="600"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        
        <main className=" bg-slate-200 my-5 md:my-0 rounded-3xl md:ml-5 p-3">
          <div className="py-5">
            <img
              className="d-block mx-auto"
              src="./images/logo1.png"
              alt=""
              width="72"
              height="57"
            />
          </div>

          <div className="row justify-center md:px-5 items-center">
            <div className="col-12">
              {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
              {message && (
                <ErrorMessage variant="success">{message}</ErrorMessage>
              )}
              {loading && <Loading />}
              <Form onSubmit={submitHandler}>
                <Row>
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>
                      First Name{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={fname}
                      placeholder="First Name"
                      maxLength={20}
                      onChange={(e) => {
                        setFname(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        // if (fname.length > 10) {
                        //   setMessage("First name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 2000);
                        //   setFname("");
                        // }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>Middle name</Form.Label>
                    <Form.Control
                      type="text"
                      value={sname}
                      placeholder="Middle name"
                      maxLength={20}
                      onChange={(e) => {
                        setSname(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        // if (sname.length > 10) {
                        //   setMessage(
                        //     "Second name cannot exceed 10 charecters!"
                        //   );
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 2000);
                        //   setSname("");
                        // }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>
                      Last name{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={lname}
                      placeholder="Last name"
                      maxLength={20}
                      onChange={(e) => {
                        setLname(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        // if (lname.length > 10) {
                        //   setMessage("Last name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 2000);
                        //   setLname("");
                        // }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-6" controlId="formBasicEmail">
                    <Form.Label>
                      Email address{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      placeholder="Enter email"
                      maxLength={50}
                      onChange={(e) => {
                        setEmail(
                          e.target.value.replace(/[^a-zA-Z0-9@._]/g, "")
                        );
                        // if (email.length > 20) {
                        //   setMessage("First name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 2000);
                        //   setEmail("");
                        // }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter valid email Address
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>
                      Phone Number{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={phone}
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setPhone(e.target.value.replace(/[^0-9]/g, ""));
                        // if (phone.length > 10) {
                        //   setMessage("Please Enter 10 digit number!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 2000);
                        //   setPhone(91);
                        // }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Phone number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>
                      Country{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={country}
                      placeholder="Enter country name"
                      onChange={(e) => {
                        setCountry(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        if (country.length > 10) {
                          setMessage(
                            "Country name cannot exceed 10 charecters!"
                          );
                          setInterval(() => {
                            setMessage(null);
                          }, 2000);
                          setCountry("");
                        }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Country
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>
                      State{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={state}
                      placeholder="Enter State"
                      onChange={(e) => {
                        setState(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        if (state.length > 10) {
                          setMessage("State Name cannot exceed 10 charecters!");
                          setInterval(() => {
                            setMessage(null);
                          }, 2000);
                          setState("");
                        }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your State
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>
                      Subject{" "}
                      <a className="required-star" href="#">
                        *
                      </a>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={subject}
                      placeholder="Enter Subject"
                      onChange={(e) => {
                        setSubject(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        if (subject.length > 10) {
                          setMessage("Subject cannot exceed 10 charecters!");
                          setInterval(() => {
                            setMessage(null);
                          }, 2000);
                          setSubject("");
                        }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Subject
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      type="text"
                      value={comment}
                      placeholder="Enter your comment"
                      onChange={(e) => {
                        setComment(e.target.value.replace(/[^a-zA-Z ]/g, ""));
                        if (comment.length > 30) {
                          setMessage("comment cannot exceed 30 charecters!");
                          setInterval(() => {
                            setMessage(null);
                          }, 2000);
                          setComment("");
                        }
                      }}
                    />
                  </Form.Group>
                </Row>

                <Button
                  style={{ margin: "1rem 0 0 0 ", backgroundColor: "#f4792c" }}
                  variant="btn btn-primary card-btn"
                  className=""
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </main>
        </div>
      </div>
    </div>
  );
}

export default Contact;
