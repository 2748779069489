// const jwt = require("jsonwebtoken");
const axios = require("axios");

const makeRequest = async (url, payload) => {
  // console.log(payload);
  // const generateToken = (id) => {
  //   return jwt.sign( "ACAHS" , "ACAHS");
  // };
  // console.log("Generated token :", generateToken(payload.id));

  const config = {
    headers: {
      "Content-type": "application/json",
      "authorization": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IkFDQUhTIiwiaWF0IjoxNjk3NjIyNzM0LCJleHAiOjE3MjkxODAzMzR9.BITjD5och6YLJHZ4PfbNiXPccSDa3rbhuz38zbBH4jg',
    },
  };
  
  let fetchedData = {}
  await axios.post(url, payload, config).then(response => {
    fetchedData = response.data
    return fetchedData
  }).catch(error => {
    console.log("Error :" ,error);
    return error;
  })
  


  return fetchedData;
}

export default makeRequest;
