import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
const Counter = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <>
      <div className="  founder-head faculty">
        <h5>At College</h5>
      </div>
      <div className="counter my-8 ">
        <div className="counter-row ">
          <div className="counter-column">
            <strong data-number="256">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={256}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef}></span>
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>Students</span>
          </div>

          <div className="counter-column">
            <strong data-number="9">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={9}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef}></span>
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>Courses</span>
          </div>

          <div className="counter-column">
            <strong data-number="11">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={11}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef}></span>
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>Departments</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;
