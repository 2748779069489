/* eslint-disable react/no-direct-mutation-state */

import React, { useState, useEffect } from "react";
import CircleConnected from "./CircleConnect";
import makeRequest from "../requestHandler/axiosRequester";
// import Loading from "../admin/Loading";
// import Message from "../admin/ErrorMessage";
// import Mha from "../courses/masterPage/Mha";
import Animation from "./Animation";
function Event() {
  return (
    <div>
      <EveImage />
      <CircleConnected />
    </div>
  );
}

function EveImage() {
  const [events, setEvents] = useState(false);
  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    try {
      const url = "https://acahs-website.vercel.app/api/event-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      // console.log("Fetched data :", response);
      if (response == null) {
        throw new Error("Can't fetch the data");
      }
      setEvents(response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
    }
  };

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempimgSrc] = useState("");
  const getImg = (eveImage) => {
    setTempimgSrc(eveImage);
    setModel(true);
  };

  if (events.length === undefined) {
    return (
      <>
        <div className="my-3">
          <div className="event-Heading  founder-head">Loading...</div>
          {/* {<Loading/>} */}
          <Animation />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="event">
          {/* <div className={model ? "model open" : "model"}>
            <img src={tempimgSrc} alt="" />
          </div> */}
          <div className="EventImg ">
            <div className="event-Heading  founder-head mb-3">Republic Day</div>
            <div className="event-cards">
              {events.map((event) => {
                if (event.eveName === "Republic Day")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      // onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className=" founder-head text-center my-12 ">
              <h1 className="event-Heading mb-3">Alvas Virasat</h1>
              <p className="text-black text-xl -mt-3 font-medium">
                National level cultural festival, national & internationally reputed artists are invited to give programs.
              </p>
            </div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Virasath")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className=" founder-head text-center my-12 ">
              <h1 className="event-Heading mb-3">Alvas Nudisiri</h1>
              <p className="text-black text-xl -mt-3 font-medium">
                It is a Kannada Literary & art festival organised by AEF every
                year to promote Kannada literature and culture.{" "}
              </p>
            </div>
          </div>{" "}
          <div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Nudisiri")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className="event-Heading  founder-head">Christmas</div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Christmas")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className="event-Heading  founder-head">Keraleeyam</div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Keraleeyam")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className="event-Heading  founder-head">
              Annual Sports Meet
            </div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Anual Sport Meet")
                  // console.log(event.eveName);
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
            <div className="event-Heading  founder-head">Graduation Day</div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Graduation day")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>

            <div className="event-Heading  founder-head">College Events</div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "College event")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>

            <div className="event-Heading  founder-head">Department Events</div>
            <div className="event-cards">
              {events.map((event) => {
                //console.log(events);
                if (event.eveName === "Department Event")
                  return (
                    <div
                      className="eventPic"
                      key={event._id}
                      onClick={() => getImg(event.eveImage)}
                    >
                      <img
                        src={event.eveImage}
                        alt="Image may take time to load!"
                      />
                      {/* <div className="card event-card">
                  </div> */}
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Event;
