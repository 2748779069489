import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar(props) {
  useEffect(() => {
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setNavigation((prevNavigation) => {
        return prevNavigation.map((item) => {
          return {
            ...item,
            current: item.href === storedActiveLink,
          };
        });
      });
    }
  }, []);

  const [navigation, setNavigation] = useState([
    { name: "Home", href: "/", current: true },
    { name: "Courses", href: "/courses", current: false },
    { name: "Facilities", href: "/facilities", current: false },
    { name: "Events", href: "/events", current: false },
    { name: "Alumini", href: "/achievers", current: false },
    { name: "Contact", href: "/contact", current: false },
  ]);

  const handleClick = (index, href) => {
    setNavigation((prevNavigation) => {
      return prevNavigation.map((item) => {
        return {
          ...item,
          current: item.href === href,
        };
      });
    });
    localStorage.setItem("activeLink", href);
  };

  const location = useLocation();

  const [navShadow, setNavShadow] = useState(false);

  const addShadow = () => {
    if (window.scrollY >= window.innerHeight / 2) {
      setNavShadow(true);
    } else {
      setNavShadow(false);
    }
  };

  window.addEventListener("scroll", addShadow);

  return (
    <>
      <div className="sticky-top z-20">
        <Disclosure
          as="nav"
          className={`bg-white navi ${navShadow ? "shadow" : ""}`}
        >
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-10 lg:hidden image-fluid"
                        src={props.img}
                        alt="ACAHS"
                      />
                      <img
                        className="hidden h-10 image-fluid lg:block"
                        src={props.img}
                        alt="ACAHS"
                      />
                      <Link className="navbar-brand fs-5 ml-3" to="/">
                        Acahs
                      </Link>
                    </div>
                    <div className="hidden sm:ml-6 sm:block">
                      <div className="flex space-x-4">
                        {navigation.map((item, index) => (
                          <Link
                            spy
                            key={item.name}
                            to={item.href}
                            isActive={() => item.href === location.pathname}
                            className={classNames(
                              item.current
                                ? "linkActive hover:no-underline text-white font-semibold "
                                : "text-black-300 hover:bg-blue-100 hover:no-underline rounded-3xl hover:text-black transition duration-300 font-semibold",
                              "px-3 py-2 text-md"
                            )}
                            onClick={() => handleClick(index, item.href)}
                            activeClassName="active"
                            activeClass="active"
                            exact
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                      type="button"
                      className="rounded-full bg-white-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    ></button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden text-center">
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {navigation.map((item, index) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "text-center hover:no-underline linkActive text-white font-semibold"
                          : " text-black-300 hover:bg-gray-100 hover:no-underline hover:text-black",
                        "block px-3 py-2 rounded-3xl  text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      activeClassName="active"
                      activeClass="active"
                      onClick={() => handleClick(index, item.href)}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
