import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import {Classroomdata} from '../../constants'
function Classroom() {
  
 return (
   <>


     <div className="facility-header text-center my-5 founder-head">Classrooms</div>{" "}
     <div className="facility-slider my-5  mx-8 ">
     < Carousel className="main-slide">

       {Classroomdata.map((Classroom) => (
       
         
             <div class="Facility-Slider-Img">
               <img src = {Classroom.img} 
               loading="lazy"
               />
         </div>
       ))}
       </Carousel>
     </div>
   </>
 );
}


export default Classroom;
