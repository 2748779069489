import React, { useState, useEffect } from "react";
import Founder from "./Founder";
import { motion } from "framer-motion";
import Vision from "./Vision";
import Allied from "./Allied";
import Features from "./Features";
import Faculty from "./Faculty";
import Principal from "./Principal";
import Fade from "react-reveal/Fade";
import Counter from "./Counter";


function Header() {

  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    const hasRendered = sessionStorage.getItem('hasRendered');
    if (hasRendered) {
      setShouldRender(false);
    } else {
      sessionStorage.setItem('hasRendered', 'true');
    }
  }, []);


  return (
    <>
     {shouldRender && (
        <div>
          <motion.div
            initial={{ y: "0" }}
            animate={{ y: "-100%" }}
            transition={{ duration: 1, delay: 3 }}
            exit={{ y: "-100%" }}
            className="bg-slate-100 h-full w-full top-0 left-0 z-50 flex flex-col justify-center fixed p-9"
          >
            <div className="flex flex-col justify-center items-center drop-shadow-2xl">
             <img src= "../images/logo1.png" className="drop-shadow-xl h-32 md:h-full" alt="" />
             <p className="font-bold text-4xl my-5 text-[#2954a2] text-center">Alva's College of Allied Health Sciences</p>
            </div>
          </motion.div>
        </div>
      )}
      <header>
        {/* <Showpage /> */}
        <div
          id="carouselExampleCaptions"
          className="carousel  slide  carousel-fade banner "
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner ">
            <div className="carousel-item active ">
              <img
                src="/images/banner1.jpg"
                className="d-block w-100 headerImg"
                alt="..."
              />
              <div className="carousel-caption d-md-block my-[-.5rem] md:my-[0rem] ">
                <h6>"MOULDING A BETTER TOMORROW"</h6>
                <Fade cascade duration={2000}>
                  <div>
                    <h5>
                      <span>A</span>lva's <span>C</span>ollege Of <span>A</span>
                      llied <span>H</span>ealth <span>S</span>ciences
                    </h5>
                  </div>
                </Fade>
                <p>
                  Recognized by Government of Karnataka
                  <br />
                  Affiliated to Rajiv Gandhi University of Health Sciences
                  <br />
                  Bangalore
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/banner2.jpg"
                className="d-block w-100 headerImg"
                alt="..."
              />

              <div className="carousel-caption d-md-block my-[-.5rem] md:my-[0rem]">
                <h6>"MOULDING A BETTER TOMORROW"</h6>
                <h5>
                  <span>A</span>lva's <span>C</span>ollege Of <span>A</span>
                  llied <span>H</span>ealth <span>S</span>ciences
                </h5>
                <p>
                  Recognized by Government of Karnataka
                  <br />
                  Affiliated to Rajiv Gandhi University of Health Sciences
                  <br />
                  Bangalore
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/banner3.jpg"
                className="d-block w-100 headerImg"
                alt="..."
              />
              <div className="carousel-caption d-md-block my-[-.5rem] md:my-[0rem]">
                <h6>"MOULDING A BETTER TOMORROW"</h6>
                <h5>
                  <span>A</span>lva's <span>C</span>ollege Of <span>A</span>
                  llied <span>H</span>ealth <span>S</span>ciences
                </h5>
                <p className="">
                  Recognized by Government of Karnataka
                  <br />
                  Affiliated to Rajiv Gandhi University of Health Sciences
                  <br />
                  Bangalore
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="waves-one">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <Vision />

        <Founder />

        <div class="waves-three">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="mx-3 md:px-28">
          <Principal />

          <Allied />
          <Features />
          <Faculty />
          <Counter />
        </div>
      </header>
    </>
  );
}

export default Header;
