import React from "react";

function Accreditation() {
  const Accr = [
    {
      title: "Rajiv Gandhi University Of Health Sciences",
      desc: "Alva's college of Medical Laboratory Technology & Post-Graduate Research Centre  has been continuously upgrading its campus infrastructure to meet the requirements of National and International Accreditation agencies. The University has constituted an Rajiv Gandhi University of Health Sciences to further enhance the quality of technical education and research.",
      img: "/images/RGUHealth-Sciences.jpg",
    },
    {
      title: "Government Of Karnataka",
      desc: "Knowledge is power and education is a path to imbibe it. Alva's Education Foundation (AEF) purports to do exactly the same that is, transform education into knowledge. It is knowledge that will empower the youth of today to understand the information explosions taking place in the rapidly globalizing world. Personal integrity and ethics can be maintained only with a strong mind and a mind can become strong only with knowledge.",
      img: "/images/State-government.jpg",
    },
  ];

  return (
    <>

      <div className="container accreditation">
        <div className="founder-head hist-head text-center accrd">
          <h5>Accreditation</h5>
        </div>
      <div className="text-center p-5 accrd-details">
        <h5>
          Alva's has been in the forefront for the past 15 years in imparting
          quality technical education and research. In order to expand its
          academic activities globally, the University has entered into
          Memoranda of Understanding (MoU) with reputed educational
          organizations based all over countrie in addition to National
          institutions and organizations with a view to sharing its expertise
          with the rest of the world and other national institutions..
        </h5>
      </div>
        <div className="row ">
          {Accr.map((accrData) => {
            return (
              <>
                <div className="col-lg-6 col-12 my-5">
                  <div className="accrtitle">{accrData.title}</div>
                  <div className="accreditation-img">
                    <img
                      src={accrData.img}
                      className="rajiv"
                      alt="rajiv gandhi"
                    />
                    <p className="accrdesc">{accrData.desc}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Accreditation;
