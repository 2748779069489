import React from "react";

function Vision() {
  return (
    <div>
      <div className="container vision md:w-[30rem] sm:w-[0rem]">
        <div className="vision-head">
          <h5>Vision</h5>
        </div>
        <div className="vision-desc">
          Alva’s Education Foundation ®aims at imparting quality education to
          equip the young generation with all those skill and competence needed
          to cope with the fast changing phase of modern life. Emphasis is
          always on high ethos, quality, integrity, humane approaches and social
          commitments.
        </div>
      </div>
    </div>
  );
}

export default Vision;
