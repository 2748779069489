import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import Fade from "react-reveal/Fade";
function Founder() {
  return (
    <>
      <div className="px-3 md:px-32 founder  ">
        <div className="row ">
          <Fade left duration={1800}>
            <div className="founder-head col-12 col-lg-5 text-center my-5">
              <h1>Founders Aspirations</h1>
              <img
                src="/images/founder.jpg"
                title="Dr. Mohan Alva"
                alt="Founder Image"
                className="founderImg"
              />
              <h3>Dr.Mohan Alva</h3>
              <h2>Chairman Alva's Education Foundation</h2>
            </div>
          </Fade>
          <Fade cascade duration={1800}>
            <div className="founders-words col-lg-6 col-12 ">
              <p>
                Alva’s Education Foundation ® was conceived and founded by
                Dr.Mohan Alva, a physician by profession and philanthropist by
                heart. Alva’s Education Foundation is a dream realized by
                Dr.Mohan Alva to impart quality education. Dr.Mohan Alva’s
                vision, dynamism, foresight, simplicity, goodwill, righteousness
                and his conviction that education lies at the root of all social
                progress are the guiding forces behind establishing the
                Foundation which came in to being in 1995 with a few Institution
                in various disciplines, making a long awaited social
                transformation a reality . Alva’s Education Foundation is
                synonymous with discipline, knowledge, punctuality, humility and
                the like.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
}

export default Founder;
