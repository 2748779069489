/* eslint-disable react/no-direct-mutation-state */

import React,{useState , useEffect} from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import makeRequest from "../requestHandler/axiosRequester";

// const dotenv = require('dotenv').config();

function AdminFacility() {
  let navigate = useNavigate();
  const [facilities , setFacilities] = useState([]);
  const [facilityId, setFacilityId] = useState("Null");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchFacility = async()=>{
    try {
      const url = "https://acahs-website.vercel.app/api/facility-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      setFacilities(await response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);   
    }
     
  };

    // fetchFacility();



    useEffect(() => {
    
      fetchFacility();
    }, []);

  return (
    <>
      <div className="container founder-head faculty ">
          <h5 className="">Facility Data</h5>
        </div>
      <div className="container">
      <Button onClick={()=>{
          navigate("/insert-facility-data")
        }}>INSERT</Button>
        <table id="admintable">
          <tr>
            <th>Name</th>
           <th>Content</th>
            <th>Image</th>
          </tr>
          {facilities.map((facility) => (
            <tr>
              <td>{facility.facilityName}</td>
              <td>{facility.facilityContent}</td>
              <td><img src={facility.facilityImage} alt="Image not found!" /></td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default AdminFacility;
