import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import ErrorMessage from "./../ErrorMessage";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

function InsFacility() {
  let navigate = useNavigate();

  const userInfo = sessionStorage.getItem("userCred");
  useEffect(() => {
    if (!userInfo) {
      navigate("/admin");
    }
  }, []);

  const [facilityName, setFacilityName] = useState("");
  const [facilityContent, setFacilityContent] = useState("");
  const [picMessage, setPicMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [facilityImage, setFacilityImage] = useState(
    "https://res.cloudinary.com/acahscollege/image/upload/v1655266445/Screenshot_2_jnsz6x.png"
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      setLoading(true);
      const { data } = await axios.post(
        "https://acahs-website.vercel.app/api/users/insdata/facility",
        {
          facilityName,
          facilityContent,
          facilityImage,
        },
        config
      );

      setLoading(false);
      navigate("/admin");
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const postDetails = (pics) => {
    setLoading(true);
    if (!pics) {
      setLoading(false);
      return setPicMessage("Please select an Image file!!");
    }
    setPicMessage(null);
    if (
      pics.type === "image/jpeg" ||
      pics.type === "image/png" ||
      pics.type === "image/jpg"
    ) {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "acahsimages");
      data.append("cloud_name", "acahscollege");
      fetch("https://api.cloudinary.com/v1_1/acahscollege/image/upload/", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setFacilityImage(data.url.toString());
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      return setPicMessage("Please select an image!");
    }
  };

  return (
    <>
      <div className="container">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
        {loading && <Loading />}
        <Form onSubmit={submitHandler}>
          <Form.Group className="col-4" controlId="Name">
            <Form.Label>Facility name</Form.Label>
            <Form.Control
              type="text"
              value={facilityName}
              placeholder="Facility Name"
              onChange={(e) => setFacilityName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-4" controlId="content">
            <Form.Label>Content</Form.Label>
            <Form.Control
              type="text"
              value={facilityContent}
              placeholder="Content"
              onChange={(e) => setFacilityContent(e.target.value)}
            />
          </Form.Group>
          {picMessage && (
            <ErrorMessage varaint="danger">{picMessage}</ErrorMessage>
          )}
          <Form.Group className="col-4">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              id="custom-file"
              custom="true"
              placeholder="Upload image"
              onChange={(e) => postDetails(e.target.files[0])}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default InsFacility;
