import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from 'axios'
import makeRequest from "../requestHandler/axiosRequester";
import Modal from "react-bootstrap/Modal";

// const dotenv = require('dotenv').config();



const axios = require("axios").default;

function AdminEvent() {
  let navigate = useNavigate();
  const [error, setError] = useState(false);

  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("Null");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 

  const getEventData = async () => {
 
    try {
      const url = "https://acahs-website.vercel.app/api/event-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      setEvents( response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      
    }



 
  };
  // getEventData();
  useEffect(() => {
    
    getEventData();
  }, []);

  const deleteEvent= async(id) => {
    console.log("deleting...");
    const url = `https://acahs-website.vercel.app/api/eventList/delete/${id}`;
    try {
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
     
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      
    }


  };
  

  return (
    <>
 <div className="container founder-head faculty ">
          <h5 className="">Event Data</h5>
        </div>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete event?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please ensure you don't need the response. If so press delete button </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              console.log("EventID :", eventId);
              deleteEvent(eventId);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container">
        <Button
          onClick={() => {
            navigate("/insert-event-data");
          }}
        >
          INSERT
        </Button>
        <Table responsive id="admintable">
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Content</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tBody>
            {events.map((event) => {
              return (
                
                  <tr>
                    <td>{event.eveName}</td>

                    <td>{event.eveContent}</td>
                    <td>
                      <img className= "Admineveimg" src={event.eveImage} alt="" />
                    </td>
                    <td><button onClick={() =>{
              
              setEventId(event._id);
              handleShow();
                    }
                  }
              >Delete</button></td>
                  </tr>
              );
            })}
          </tBody>
        </Table>
      </div>
    </>
  );
}

export default AdminEvent;
