import React,{useState , useEffect} from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from 'axios'
import Modal from "react-bootstrap/Modal";

import makeRequest from "../requestHandler/axiosRequester";

// const dotenv = require('dotenv').config();




function AdminFaculty() {
  let navigate = useNavigate();
  const [faculties , setFaculties] = useState([]);
  const [show, setShow] = useState(false);  
  const [facultyId, setFacultyId] = useState("Null");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchFaculty = async()=>{
    try {
      const url = "https://acahs-website.vercel.app/api/faculty-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched courses :", response);
      if (!response) {
        console.log("Can't fetch the data");
      }
      else{
      setFaculties(await response);
      }
    } catch (e) {
      console.log("Can't fetch the data : ", e);
      
    } 

    
  };

    // fetchFaculty();
    useEffect(() => {
    
      fetchFaculty();
    }, []);

  const deleteFaculty = async(id) => {
   
    const url =`https://acahs-website.vercel.app/api/facultyList/delete/${id}`;
    try {
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);

      if (response == null) {
        console.log("Can't fetch the data");
      }
      
    } catch (e) {
      console.log("Can't fetch the data : ", e);  
    }

    // Axios.delete(process.env.REACT_APP_BackendURL+`/api/facultyList/delete/${id}`);
  };


  return (
    <>
     <div className="container founder-head faculty ">
          <h5 className="">Faculty Data</h5>
        </div>
         

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Delete faculty?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please ensure you don't need the response. If so press delete button </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              console.log("facultyId :", facultyId);
              deleteFaculty(facultyId);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
      <Button onClick={()=>{
          navigate("/insert-faculty-data")
        }}>INSERT</Button>
        <table id="admintable">
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Location</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
          {faculties && faculties.map((faculty) => (
            <tr>
              <td>{faculty.fname}</td>
              <td>{faculty.mname}</td>
              <td>{faculty.lname}</td>
              <td>{faculty.email}</td>
              <td>{faculty.phone}</td>
              <td>{faculty.designation}</td>
              <td>{faculty.department}</td>
              <td>{faculty.location}</td>
              <td><img className= "Admineveimg" src={faculty.facimage} alt="Image not found!" /></td>
              <td> <button onClick={() =>{
               setFacultyId(faculty._id)
               handleShow();
               }
              }
              >Delete</button></td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default AdminFaculty;
