import { Spinner } from "react-bootstrap";
import React from "react";


const ImageLoading = ({ size = 50 }) => {
  return (
    <div
      style=
      {{
        display: "flex",
        alignItems: "center",
        padding:"10px", 
        width: "100%",
        height: "100%",
      }}
      >
      <Spinner
        style={{
          width: size,
          height: size,
        }}
        animation="border"
        size="sm"
        variant="warning"
      />
    </div>
  );
};

export default ImageLoading;
