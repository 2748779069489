import React, { useState, useEffect } from "react";
import axios from "axios";
import Contact from "./../contact/Contact";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import makeRequest from "../requestHandler/axiosRequester";

// const dotenv = require("dotenv").config();

function AdminContact() {
  let navigate = useNavigate();
  const [contactData, setContactData] = useState([]);
  const [show, setShow] = useState(false);
  const [contactID, setContactID] = useState("Null");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchContact = async () => {
    try {
      const url = "https://acahs-website.vercel.app/api/contact-data";
      const id = "ACAHS";
      const payload = {
        id,
      };

      const response = await makeRequest(url, payload);
      console.log("Fetched contacts :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      setContactData(response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
    }
  };
  useEffect(() => {
    fetchContact();
  },[]);

 
  const deleteContact = async (contactid) => {
    console.log("Deleting contact.........", contactid);
    const url = `https://acahs-website.vercel.app/api/contactList/delete/${contactid}`;
    const id = "ACAHS";
    const payload = {
      id,
    };

    try {
      const response = await makeRequest(url, payload);
      console.log("Fetched data :", response);
      if (response == null) {
        console.log("Can't fetch the data");
      }
      console.log(response);
    } catch (e) {
      console.log("Can't fetch the data : ", e);
    }
  };

  return (
    <>
      <div className="faciliImg">
        <h1>Admin</h1>
      </div>
      <div className="adminButton d-flex justify-center py-3">
        <Button
          className="d-flex justify-center"
          onClick={() => {
            sessionStorage.removeItem("userCred");
            navigate("/");
          }}
        >
          Logout
        </Button>
      </div>
      <div className="container founder-head faculty">
          <h5>Contacts</h5>
        </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete contact?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please ensure you don't need the response. If so press delete button </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              console.log("contactID :", contactID);
              deleteContact(contactID);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <table id="admintable">
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>State</th>
            <th>Phone</th>
            <th>Subject</th>
            <th>Comment</th>
            <th>Operation</th>
          </tr>
          {contactData.map((contact) => (
            <tr>
              <td>{contact.fname}</td>
              <td>{contact.sname}</td>
              <td>{contact.lname}</td>
              <td>{contact.email}</td>
              <td>{contact.country}</td>
              <td>{contact.state}</td>
              <td>{contact.phone}</td>
              <td>{contact.subject}</td>
              <td>{contact.comment}</td>

              <td>
                <button
                  onClick={() => {
                    {
                      setContactID(contact._id);
                      handleShow();
                    }
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default AdminContact;
