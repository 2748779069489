import React, {  useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import ErrorMessage from "./../ErrorMessage";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import ImageLoading from "../imageLoading";
const InsAdmin = () => {
  let navigate = useNavigate();

  // const userInfo = sessionStorage.getItem("userCred");
  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate("/admin");
  //   }
  // }, []);

  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [picMessage, setPicMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [adminImage, setAdminImage] = useState(
    "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      setLoading(true);
      const { data } = await axios.post(
        "https://acahs-website.vercel.app/api/users/insData/admin",
        {
          adminName,
          adminEmail,
          password,
          adminImage,
        },
        config
      );

      setLoading(false);
      navigate("/admin");
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const postDetails = (pics) => {
    setImageLoading(true);
    if (!pics) {
      setImageLoading(false);
      return setPicMessage("Please select an Image file!!");
    }
    setPicMessage(null);
    if (
      pics.type === "image/jpeg" ||
      pics.type === "image/png" ||
      pics.type === "image/jpg"
    ) {
      console.log(pics);

      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "acahsimages");
      data.append("cloud_name", "acahscollege");

      console.log(data);
      fetch("https://api.cloudinary.com/v1_1/acahscollege/image/upload/", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setAdminImage(data.url.toString());
          setImageLoading(false);
        })
        .catch((err) => {
          setImageLoading(false);
          console.log(err);
        });
    } else {
      setImageLoading(false);
      return setPicMessage("Please select an image!");
    }
  };

  return (
    <>
      <div className="container">
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
        {loading && <Loading />}
        <Form onSubmit={submitHandler}>
          <Form.Group className="col-4" controlId="Name">
            <Form.Label>Admin name</Form.Label>
            <Form.Control
              type="text"
              value={adminName}
              placeholder="Admin Name"
              onChange={(e) => setAdminName(e.target.value)}
              required="true"
            />
          </Form.Group>
          <Form.Group className="col-4" controlId="content">
            <Form.Label>Admin Email</Form.Label>
            <Form.Control
              type="email"
              value={adminEmail}
              placeholder="Email Address"
              onChange={(e) => setAdminEmail(e.target.value)}
              required="true"
            />
          </Form.Group>
          <Row>
            <Form.Group className="col-4" controlId="Name">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
                required="true"
              />
            </Form.Group>
          </Row>
          {picMessage && (
            <ErrorMessage varaint="danger">{picMessage}</ErrorMessage>
          )}

          <Form.Group className="col-4">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              id="custom-file"
              custom="true"
              placeholder="Upload image"
              onChange={(e) => postDetails(e.target.files[0])}
              required="true"
            />
            {imageLoading && <ImageLoading />}
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
};

export default InsAdmin;
