import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React,{useEffect} from "react";
import ScrollToTop from "./components/ScrollToTop";
import NavBar from "./components/header/NavBar";
import Header from "./components/header/Header";
import Contact from "./components/contact/Contact";
import Events from "./components/eves/Event";
import Facilities from "./components/facilities/Facilities";
import Footer from "./components/footer/Footer";
import Courses from "./components/courses/Courses";
import Achievers from "./components/achievers/Achievers";
import Admin from "./components/admin/admin";
import Login from "./components/admin/adminLogin";
import InsEvent from "./components/admin/InsertData/InsEvent";
import InsFaculty from "./components/admin/InsertData/insFaculty";
import InsFacility from "./components/admin/InsertData/insFacility";
import Accreditation from "./components/header/Accreditation";
import Enroll from "./components/courses/Enroll";
import Whyacahs from "./components/footer/Whyacahs";
import History from "./components/footer/History";
import InsAdmin from "./components/admin/InsertData/insAdmin";
import logo from "./logo1.png"
function App() {
 
  return (
    <>

          <Router>
            <NavBar img={logo}/>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Header />}></Route>
              <Route exact path="/contact" element={<Contact />}></Route>
              <Route exact path="/events" element={<Events />}></Route>
              <Route exact path="/facilities" element={<Facilities />}></Route>
              <Route exact path="/courses" element={<Courses />}></Route>
              <Route exact path="/achievers" element={<Achievers />}></Route>
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="/admin" element={<Admin />}></Route>
              <Route
                exact
                path="/insert-event-data"
                element={<InsEvent />}
              ></Route>
              <Route
                exact
                path="/insert-faculty-data"
                element={<InsFaculty />}
              ></Route>
              <Route
                exact
                path="/insert-facility-data"
                element={<InsFacility />}
              ></Route>
              <Route
                exact
                path="/accreditation"
                element={<Accreditation />}
              ></Route>
              <Route exact path="/enroll/:id" element={<Enroll />}></Route>
              <Route exact path="/history" element={<History />}></Route>
              <Route
                exact
                path="/insert-admin-data"
                element={<InsAdmin />}
              ></Route>
              <Route exact path="/whyacahs" element={<Whyacahs />}></Route>
            </Routes>

            <Footer />
          </Router>
    </>
  );
}

export default App;
