import { useState, useEffect, useContext } from "react";
import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import ErrorMessage from "../admin/ErrorMessage";
import Loading from "../admin/Loading";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import makeRequest from "../requestHandler/axiosRequester";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

const Enroll = () => {
  const { id }: { id: string } = useParams();
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  // const [subject, setSubject] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalloading, setmodlalLoading] = useState([]);
  const [picMessage, setPicMessage] = useState(null);
  const [courses, setCourses] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);

  const subject = id && JSON.stringify(id).replace(/[^a-zA-Z ]/g, "");

  const getCourseData = async () => {
    setLoading(true);
    try {
      console.log(subject);
      const url = "https://acahs-website.vercel.app/api/course-data";
      // const id = "ACAHS";
      const payload = {
        id: "ACAHS",
        filter: {
          CourseName: subject,
        },
      };

      const response = await makeRequest(url, payload);

      if (response != null) {
        setCourses(response.data);
      }
      throw new Error("Fetched data is null");
    } catch (e) {
      // console.log("Can't fetch the data : " + e);

      setLoading(false);
    }
  };
  console.log("courses log from enroll", courses);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = "https://acahs-website.vercel.app/api/users/insdata/enroll";
      const id = "ACAHS";
      const payload = {
        id,
        fname,
        mname,
        lname,
        email,
        country,
        state,
        phone,
        subject,
      };
      const enrollresponse = await makeRequest(url, payload);
      console.log("res" + enrollresponse);
      if (!enrollresponse) {
        throw new Error("Can't fetch the data");
      }

      if (enrollresponse.fname === undefined) {
        setLoading(false);
        setMessage(`This email is already registered. Use different email id.`);
      } else {
        setLoading(false);
        setMessage(
          `Hello ${enrollresponse.fname} your enrollment form has been sent. Thank you for enrolling.`
        );
      }
      const doc = new jsPDF("portrait", "px", "a4", "false");
      doc.addImage("./images/logo1.png", "PNG", 180, 20, 80, 80);
      doc.setFont("courier");
      doc.setFontType("normal");
      doc.setFillColor("Gray");
      doc.setFontSize(25);
      doc.text(70, 120, "Alva's College of Allied Health Science");
      doc.setFontSize(17);
      doc.text(100, 150, "FIRST NAME :");
      doc.text(300, 150, fname);
      doc.text(100, 200, "MIDDLE NAME :");
      doc.text(300, 200, mname);
      doc.text(100, 250, "LAST NAME :");
      doc.text(300, 250, lname);
      doc.text(100, 300, "EMAIL :");
      doc.text(300, 300, email);
      doc.text(100, 350, "PHONE :");
      doc.text(300, 350, phone);
      doc.text(100, 400, "COUNTRY :");
      doc.text(300, 400, country);
      doc.text(100, 450, "STATE :");
      doc.text(300, 450, state);
      doc.text(100, 500, "SUBJECT :");
      doc.text(300, 500, subject);
      doc.save("enroll-acahs.pdf");
    } catch (error) {
      // setError(error.message);
      setLoading(false);
    }
  };

  const [modal, setModal] = useState(false);

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getCourseData();

    setTimeout(() => {
      setModal(true);
    }, 1000);
  }, []);

  return (
    <div>
      <div className="container">
        <main>
          <div className="py-5 text-center">
            <Link to="/">
              <img
                className="d-block mx-auto mb-4"
                src="/images/logo1.png"
                alt=""
                width="72"
                height="57"
              />
            </Link>

            <div className="flex justify-center  align-center">
              {courses &&
                courses.length > 0 &&
                courses.map((data) => {
                  return (
                    <>
                      <div className="enroll-img relative">
                        <img
                          className="img-fluid rounded-start card-img"
                          src={data.CourseImage}
                          alt=""
                        />
                        <h5 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-2 founder-head">
                          {data.CourseName}
                        </h5>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>

          <div>
            {courses &&
              courses.length > 0 &&
              courses.map((data) => {
                return (
                  <>
                    <div className="department  founder-head flex mt-9 ">
                      <h5>About Department</h5>
                    </div>
                    <p className="my-2 text-xl ">{data.CourseDesc}</p>
                  </>
                );
              })}
          </div>
          <div className="my-4">
            <ul className="my-2 text-xl">
              {courses &&
                courses.length > 0 &&
                courses.map((data) => {
                  return (
                    <>
                      <div className=" secondary fw-bold fs-5 underline underline-offset-4 ">
                        <h2>Eligibility Criteria</h2>
                      </div>
                      <li>{data.CourseEligibility["Eligibility"]}</li>
                    </>
                  );
                })}
            </ul>
          </div>
          <div className="my-4">
            <ul className="my-2 text-xl">
              {courses &&
                courses.length > 0 &&
                courses.map((data, index) => {
                  const opportunities =
                    data &&
                    data.CourseEligibility &&
                    data.CourseEligibility.Opportunity &&
                    data.CourseEligibility.Opportunity.split("\n");

                  return (
                    <React.Fragment key={index}>
                      <div className=" secondary fw-bold fs-5 underline underline-offset-4 ">
                        <h2>Job Opportunities</h2>
                      </div>
                      {opportunities &&
                        opportunities.length > 0 &&
                        opportunities.map((opp, oppIndex) => (
                          <li key={`${index}-${oppIndex}`}>{opp}</li>
                        ))}
                    </React.Fragment>
                  );
                })}
            </ul>
          </div>

          {courses &&
            courses.length > 0 &&
            courses.map((course, index) => (
              <div key={index} className="flex mr-7 flex-col my-2">
                {course.Faculties && course.Faculties.length > 0 && (
                  <>
                    <h5 className="underline underline-offset-4 fw-bold fs-5">
                      Department Faculties
                    </h5>

                    <div className=" facility-slider my-5  mx-8">
                      <Carousel className="main-slide">
                        {course.Faculties.map((faculty, index2)  => (
                          <div key={`${index}-${index2}`}>
                            <div className="facCard  shadow-md m-2 rounded ">
                              <div className=" p-0">
                                <img
                                  src={faculty.facimage}
                                  className="w-10 card-img-top"
                                />
                                <div className="p-4">
                                  <h5 className="mb-0">
                                    {faculty.fname} {faculty.mname}{" "}
                                    {faculty.lname}
                                  </h5>
                                  <p className="medium text-muted">
                                    {faculty.designation}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </>
                )}
              </div>
            ))}

          {courses &&
            courses.length > 0 &&
            courses.map((course, index) => (
              <div key={index} className="flex mr-7 flex-col my-2">
                {course.Events && course.Events.length > 0 && (
                  <>
                    <h5 className="underline underline-offset-4 fw-bold fs-5">
                      Department Events
                    </h5>

                    <div className="container p-[3rem]">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-1 ">
                        {course.Events.map((event, index2) => (
                          <>
                            <div className="relative">
                              <div className="absolute  inset-0 z-10 flex transition duration-200 ease-in hover:opacity-0">
                                <div className="absolute inset-0 bg-black opacity-70"></div>
                                <div className="mx-auto text-white z-10 self-center uppercase">
                                  <p>{event.eveName}</p>
                                </div>
                              </div>
                              <img src={event.eveImage} alt="" />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}

          {courses &&
            courses.length > 0 &&
            courses.map((course, index) => (
              <div key={index} className="flex mr-7 flex-col my-2">
                {course.RankHolders && course.RankHolders.length > 0 && (
                  <>
                    <h5 className="underline underline-offset-4 fw-bold fs-5">
                      Department Rank Holders
                    </h5>

                    <div className=" facility-slider my-5  mx-8  ">
                      <Carousel className="main-slide">
                        {course.RankHolders.map((holder, index2) => (
                          <div key={`${index}-${index2}`}>
                            <div className="facCard shadow-md m-2 rounded ">
                              <div className="card-body p-0">
                                <img
                                  src={holder.Image}
                                  className="w-10 card-img-top"
                                />
                                <div className="p-4">
                                  <h5 className="mb-0">{holder.Name}</h5>
                                  <p className="medium text-muted">
                                    {holder.rank}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </>
                )}
              </div>
            ))}

          {modal && (
            <div className="">
              <div className="">
                <div className=" founder-head ">
                  <h5>Enrollment form</h5>
                </div>

                {/* {modalloading && <Loading />} */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    {courses &&
                      courses.length > 0 &&
                      courses.map((data) => {
                        return (
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                {data.CourseName}
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body ">
                              <div className="criteria">
                                <b> courses CRITERIA :</b> {data.courses}
                              </div>
                              <div className="details"></div>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row g-5 flex">
            <div className="col-md-7 col-lg-8">
              {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
              {message && (
                <ErrorMessage variant="success">{message}</ErrorMessage>
              )}
              {loading && <Loading />}
              <Form onSubmit={submitHandler}>
                <div className="text-center">
                  <Form.Group className="" controlId="formBasicName">
                    <Form.Control
                      className="text-center fw-bolder fs-5 py-5 my-5"
                      type="text"
                      value={id}
                      required
                      // onChange={(e) => setSubject(e.target.value)}
                    />
                  </Form.Group>
                </div>

                {/* Department info */}

                <Row className="">
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      value={fname}
                      placeholder="First name"
                      maxLength={20}
                      onChange={(e) => {
                        setFname(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                            : ""
                        ); // if (fname.length > 20) {
                        //   setMessage("First name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setFname("");
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>Middle name</Form.Label>
                    <Form.Control
                      type="text"
                      value={mname}
                      placeholder="Middle name"
                      maxLength={20}
                      onChange={(e) => {
                        setMname(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                            : ""
                        ); // if (mname.length > 10) {
                        //   setMessage(
                        //     "Second name cannot exceed 10 charecters!"
                        //   );
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setMname("");
                        // }
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-4" controlId="formBasicName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lname}
                      placeholder="Last name"
                      maxLength={20}
                      onChange={(e) => {
                        setLname(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                            : ""
                        ); // if (lname.length > 10) {
                        //   setMessage("Last name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setLname("");
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group className="col-6" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      placeholder="Enter email"
                      maxLength={30}
                      onChange={(e) => {
                        setEmail(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z0-9@._]/g, "")
                            : ""
                        );
                        // if (email.length > 20) {
                        //   setMessage("First name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setEmail("");
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      value={phone}
                      placeholder="Enter your number"
                      maxLength={10}
                      onChange={(e) => {
                        setPhone(
                          e.target.value
                            ? e.target.value.replace(/[^0-9]/g, "")
                            : ""
                        );
                        // if (phone.length > 10) {
                        //   setMessage("Please Enter 10 digit number!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setPhone(91);
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="con-state-row mt-2">
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      value={country}
                      placeholder="Enter country name"
                      maxLength={20}
                      onChange={(e) => {
                        setCountry(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                            : ""
                        );
                        // if (country.length > 10) {
                        //   setMessage(
                        //     "Country name cannot exceed 10 charecters!"
                        //   );
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setCountry("");
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-6" controlId="formBasicName">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      value={state}
                      placeholder="Enter State"
                      maxLength={20}
                      onChange={(e) => {
                        setState(
                          e.target.value
                            ? e.target.value.replace(/[^a-zA-Z ]/g, "")
                            : ""
                        );
                        // if (state.length > 10) {
                        //   setMessage("State Name cannot exceed 10 charecters!");
                        //   setInterval(() => {
                        //     setMessage(null);
                        //   }, 3000);
                        //   setState("");
                        // }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                <Button
                  style={{ margin: "1rem 0 0 0 ", backgroundColor: "#f4792c" }}
                  variant="btn btn-primary card-btn"
                  type="submit"
                  className="btn btn-primary my-4 d-flex enrollBtn"
                >
                  Submit
                </Button>
                <Link to="/courses">Go back</Link>
              </Form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Enroll;
