import {
  class1,
  class2,
  class3,
  hostel1,
  hostel2,
  hostel3,
  hostel4,
  hostel5,
  lab1,
  lab2,
  lab10,
  lab11,
  lab3,
  lab4,
  lab5,
  lab6,
  lab7,
  lab8,
  lab9,
  lab12,
  lab13,
lib1,lib2,lib3,lib4,
} from "../assets";

export const Classroomdata = [
  {
    img: class1,
  },
  {
    img: class2,
  },
  {
    img: class3,
  },
];

export const Hosteldata = [
  {
    imgscr: hostel2,
  },
  {
    imgscr: hostel1,
  },
  {
    imgscr: hostel3,
  },
  { imgscr: hostel4 },
  { imgscr: hostel5 },
];

export const LaboratoryData = [
  {
    img: lab1,
  },
  {
    img: lab2,
  },
  {
    img: lab3,
  },
  { img: lab4 },
  { img: lab5 },
  { img: lab6 },
  { img: lab7 },
  { img: lab8 },
  { img: lab9 },
  { img: lab10 },
  
];

export const Librarydata =[
    {img: lib1},{img: lib2},{img: lib3},{img: lib4}
]