import React, { useEffect } from "react";
import AdminFacility from "./adminFacility";
import AdminContact from "./adminContact";
import AdminEvent from "./adminEvent";
import AdminFaculty from "./adminFaculty";
import { useNavigate } from "react-router-dom";
import AdminEnroll from "./adminEnroll";
import AdminCourses from "./adminCourses"
function Admin() {
  let navigate = useNavigate();

  const userInfo = sessionStorage.getItem("userCred");
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  
    
  }, []);
 
   

  return (
    <>
    
      <AdminContact />
      <AdminEnroll />
      <AdminEvent />
      <AdminFaculty />
      <AdminCourses />
    </>
  );
}

export default Admin;
